import Glide from "@glidejs/glide";

[...document.querySelectorAll(".slider")].forEach((element) => {

    const slider = element.querySelector(".glide");
    const settings = JSON.parse(slider.dataset?.glide || null);
    const glide = new Glide(slider, {
        type: 'slider',
        ...settings,
    });

    glide.mount();
});
